import React, { createContext, useState, useContext, ReactNode } from 'react';

interface HighlightTerm {
    searchTerm: any;
}

interface HighlightContextType {
    searchHighlightTerm : HighlightTerm;
    addTerm : (term : HighlightTerm)=>void;
    removeAllTerm : ()=>void;
}

const HighlightContext=createContext<HighlightContextType | any>(undefined)

export const useHighlightTerm=():HighlightContextType=> {
    const context=useContext(HighlightContext)
    if(!context){
        throw new Error("useHighlightTerm must be used within a context provider")
    }
    return context;
}

interface HighlightProviderProps { 
    children: ReactNode;
}

export const HighlightTermProvider=({children}:HighlightProviderProps):JSX.Element=>{
    const [searchHighlightTerm,setSearchHighlightTerm]=useState<HighlightTerm>({searchTerm:""})


    const addTerm=(term:HighlightTerm):void=>{
        let searchTerm:string=String(term.searchTerm).replaceAll(";"," ")
        setSearchHighlightTerm({searchTerm})
    }

    const removeAllTerm=():void=>{
        setSearchHighlightTerm({searchTerm:''})
    }

    return (
        <HighlightContext.Provider value={{addTerm,removeAllTerm,searchHighlightTerm}}>
            {children}
        </HighlightContext.Provider>
    )
}