import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './static/login.css';
import api from './api/endpoints';

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const particles = useRef<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", (e) => {
                const newColorScheme = e.matches ? "dark" : "light";
                if (particles.current) {
                    particles.current.loadTheme(newColorScheme);
                }
            });
    }, []);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission

        // Create a FormData object and append username and password
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        try {
            // Make a POST request to your FastAPI backend
            const response = await fetch(`${api.baseApi}/auth/login`, {
                method: 'POST',
                body: formData,
            });

            // Check if the response is OK (status code 200-299)
            if (!response.ok) {
                const message = await response.text();
                throw new Error(`Failed to log in: ${message}`);
            }

            const data = await response.json();
            if (response.ok && data.data === 'Success') {
                // Store each detail in local storage individually
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('informaticscustomer_id', data.informaticscustomer_id);
                localStorage.setItem('session_key', data.session_key);
                localStorage.setItem('username', data.user_name);
                navigate("/");
            }else{
                navigate('/login');
                setError('Invalid username or password.');
            }

        } catch (error: any) {
            //setError(error.message);
            setError('Invalid username or password.');
        }
    };

    return (
        <div className='login-wrapper'>
            <main className="box">
                <form onSubmit={handleLogin}>
                    <h3>Login</h3>

                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        placeholder="Email or Username"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        placeholder="Password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <button type="submit">Log In</button>
                </form>
            </main>
            <footer></footer>
        </div>
    );
}

export default Login;
