// src/components/SessionManagement.tsx

import { useCallback, useEffect } from 'react';
import api from './api/endpoints';


declare global {
    interface Window {
      idleTimeout: ReturnType<typeof setTimeout>;
    }
  }
  
  // This line makes sure the file is treated as a module.
  export {};

const SessionManagement = () => {
  
  const handleLogout = useCallback(() => {
    sessionStorage.clear(); // Clear session storage
    localStorage.clear();
    window.location.href = `${api.frent_end_url}`
  }, []);

  const startIdleTimer = useCallback(() => {
    window.idleTimeout = setTimeout(() => {
      handleLogout();  // Log out after 2 minutes
    }, 30 * 60 * 1000);  // 2 minutes
  }, [handleLogout]);

  const resetTimer = useCallback(() => {
    clearTimeout(window.idleTimeout);
    startIdleTimer();
  }, [startIdleTimer]);

  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('scroll', resetTimer);

    // Start the idle timer initially
    startIdleTimer();

    // Clean up listeners and timeout on unmount
    return () => {
      clearTimeout(window.idleTimeout);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, [resetTimer, startIdleTimer]);

  return null;  // No need to render any UI
};

export default SessionManagement;
