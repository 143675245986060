import * as React from 'react';
import { useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import type { SingleKeyword } from '@react-pdf-viewer/search'; // Import keyword type
import { searchPlugin } from '@react-pdf-viewer/search';
import {useHighlightTerm} from '../utils/HighlightContext'

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface JumpToFirstMatchExampleProps {
    fileUrl: string;
    keyword: SingleKeyword | SingleKeyword[];
}

const JumpToFirstMatchExample: React.FC<JumpToFirstMatchExampleProps> = ({ fileUrl, keyword }) => {
    const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
    const handleDocumentLoad = () => setDocumentLoaded(true);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { toolbarPluginInstance } = defaultLayoutPluginInstance;
    const { searchPluginInstance } = toolbarPluginInstance;

    const {searchHighlightTerm,removeAllTerm}=useHighlightTerm()
    // const { highlight } = searchPluginInstance;

    React.useEffect(() => {
        if (isDocumentLoaded) {
            highlight(keyword);
        }
    }, [isDocumentLoaded]);

    React.useEffect(()=>{

        if(searchHighlightTerm?.searchTerm!==''){
            searchAndHighlight(searchHighlightTerm?.searchTerm)
        }else{
            searchAndHighlight(' ')
        }
    },[searchHighlightTerm.searchTerm])

    //customize the highlight width, height and other properties here

    const renderHighlights = React.useCallback(
        (renderProps:any) => {
            return (
                <>
                    {renderProps.highlightAreas.map((area:any, index:any) => (
                        <div className={`highlight`}
                            key={`${area.pageIndex}-${index}`}
                            style={{
                                position: 'absolute',
                                // background: 'red',
                                opacity: '0.4',
                                ...renderProps.getCssProperties({...area,height:area.height+5,width:area.width+40})
                            }}
                        >
                        </div>
                    ))}
                </>
            );
        },
        [searchHighlightTerm]
    );

    const searchPluginInstanceMain = searchPlugin({ renderHighlights });

    const { highlight,clearHighlights } = searchPluginInstanceMain;

    const searchAndHighlight = (keyword:any) => {
        if(keyword==' '){
            clearHighlights()
        }else{

            highlight({ keyword, matchCase: false, wholeWords: false });
        }
    };


    return (
        <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance,searchPluginInstanceMain]}
            onDocumentLoad={handleDocumentLoad}
        />
    );
};

export default JumpToFirstMatchExample;
