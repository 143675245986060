// fetchFileDetails.tsx
import api from "./api/endpoints";

interface FileDetails {
  user_pdf_file_name: string;
  created_date: string;
  no_of_qn: number;
  ai_app_file_details_id: number;
  // Add any other fields you expect in the response
}

const fetchFileDetails = async (detailsId: number): Promise<FileDetails | null> => {
  try {
    const queryParams = new URLSearchParams();
        queryParams.append(
          "pdf_file_id",
          detailsId.toString()
        );
    const response = await fetch(
      `${api.baseApi}/api/get_ai_pdf_file_details/?${queryParams}`,
      {
        method: "GET",
      }
    );
    if (response.ok) {
      const data = await response.json();
      
      const fileDetails = data.data[0];
      console.log(fileDetails);

      console.log(fileDetails.blob_file_path);
      console.log(fileDetails.ai_app_file_id);
      localStorage.setItem("pdf_file_path", fileDetails.blob_file_path);
      localStorage.setItem("pdf_file_id", fileDetails.ai_app_file_id);
      localStorage.setItem("is_reexcute", 'true');
      // Convert chat history to Message format
      const chatHistory = fileDetails.chat_history.map((chat: any) => [
        { text: chat.user_question, sender: "user" },
        { text: chat.ai_answer, sender: "bot", isMarkdown: true },
      ]).flat(); // Flatten array to get alternating messages

      localStorage.setItem("chatMessages", JSON.stringify(chatHistory)); // Save in localStorage
      //localStorage.removeItem("suggestionsData");
      return data; // Return file details on success
    } else {
      console.error("Failed to fetch file details");
      return null;
    }
  } catch (error) {
    console.error("Error fetching file details:", error);
    return null;
  }
};

export default fetchFileDetails;
