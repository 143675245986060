import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  SnackbarContent,
  CircularProgress,
} from "@mui/material";
import Layout from "./Layout";
import "./static/pdfhistory.css";
import api from "./api/endpoints";
import fetchFileDetails from "./fetchFileDetails";

interface HistoryItem {
  row_number: number;
  user_pdf_file_name: string;
  created_date: string;
  no_of_qn: number;
  ai_app_file_details_id: number;
  is_saved: boolean | null;
}

const PdfHistory: React.FC = () => {
  const [searchHistory, setSearchHistory] = useState<HistoryItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [topicName, setTopicName] = useState("");
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [isSaved, setIsSaved] = useState(false); // For toggling save to delete
  const [showSnackbar, setShowSnackbar] = useState(false); // To show success pop-up
  const selectedRowDetailsIdRef = useRef<number | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [fileDetails, setFileDetails] = useState<HistoryItem | null>(null);
  const [pendingDetailsId, setPendingDetailsId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const userId = localStorage.getItem("user_id");
  const sessionId = localStorage.getItem("session_key");
  //const sessionId = "58a63909197c4e24a41b72cb8cafdf2d";
  const informaticscustomerId = localStorage.getItem("informaticscustomer_id");

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const queryParams = new URLSearchParams();
        if (userId) queryParams.append("user_id", userId);
        if (sessionId) queryParams.append("session_id", sessionId);
        if (informaticscustomerId)
          queryParams.append("customer_id", informaticscustomerId);
        const response = await fetch(
          `${api.baseApi}/api/get_ai_search_history/?${queryParams.toString()}`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        if (response.ok && data.response) {
          setSearchHistory(data.response);
        }
      } catch (error) {
        console.error("Error fetching history:", error);
      }
    };
    fetchHistory();
  }, [userId, sessionId, informaticscustomerId, showSnackbar]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    rowId: number,
    detailsId: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
    selectedRowDetailsIdRef.current = detailsId;
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const handleSaveClick = () => {
    setOpenDialog(true);
    handleClosePopover();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setTopicName("");
  };

  const handleSaveTopic = async () => {
    try {
      if (selectedRowDetailsIdRef.current !== null) {
        const queryParams = new URLSearchParams();
        queryParams.append(
          "pdf_file_id",
          selectedRowDetailsIdRef.current.toString()
        );
        if (userId) queryParams.append("user_id", userId.toString());
        const response = await fetch(
          `${api.baseApi}/api/save_ai_history/?${queryParams.toString()}`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        if (response.ok && data.data === 'SUCCESS') {
          console.log(data.data)
          setIsSaved(true); // Toggle save to delete
          setSnackbarMessage("PDF saved successfully");
          setShowSnackbar(true); // Show pop-up
        } else if (data.data == "Limit exceed"){
          setIsSaved(true);
          setSnackbarMessage("Limit exceed: unable to save PDF");
          setShowSnackbar(true);
        }
        else {
          console.error("Failed to save PDF");
        }
      }
      handleDialogClose();
    } catch (error) {
      console.error("Error saving PDF:", error);
    }
  };

  const handleDeleteTopic = async () => {
    try {
      if (selectedRowDetailsIdRef.current !== null) {
        const queryParams = new URLSearchParams();
        queryParams.append(
          "pdf_file_id",
          selectedRowDetailsIdRef.current.toString()
        );
        const response = await fetch(
          `${api.baseApi}/api/delete_ai_history/?${queryParams.toString()}`,
          {
            method: "GET",
          }
        );
        if (response.ok) {
          setSnackbarMessage("PDF deleted successfully");
          setShowSnackbar(true); // Show pop-up
        } else {
          console.error("Failed to delete PDF");
        }
      }
    } catch (error) {
      console.error("Error saving PDF:", error);
    }
  };


  const handlePrint = async () => {
    try {
      if (selectedRowDetailsIdRef.current !== null) {
        const queryParams = new URLSearchParams();
        queryParams.append(
          "pdf_file_id",
          selectedRowDetailsIdRef.current.toString()
        );
        const response: any = await fetch(
          `${api.baseApi}/api/print_pdf_data/?${queryParams.toString()}`,
          {
            method: "GET",
          }
        );
        window.open(response?.url);
        if (response.ok) {
          console.log("PDF printed successfully");
        } else {
          console.error("Failed to print PDF");
        }
      }
      handleClosePopover();
    } catch (error) {
      console.error("Error printing PDF:", error);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilenameClick = async (detailsId: number) => {
    setPendingDetailsId(detailsId); // Store the details ID
    setOpenDiscardDialog(true); // Open discard dialog
  };
  const handleDiscardConfirm = async () => {
    setLoading(true);
    if (pendingDetailsId !== null) {
      const details = await fetchFileDetails(pendingDetailsId);
      //setFileDetails(details);
      navigate("/");
      setSnackbarMessage(`Data for ${details?.user_pdf_file_name} reloaded`);
      setShowSnackbar(true);
    }
    setLoading(false);
    setOpenDiscardDialog(false); // Close discard dialog
  };
  const handleDiscardCancel = () => {
    setOpenDiscardDialog(false); // Close discard dialog without action
  };
  return (
    <Layout>
      <div
        className="history"
        style={{ marginTop: "40px", flex: 1, overflow: "auto" }}
      >
        <Typography variant="h6" gutterBottom>
          PDF & QA History
        </Typography>
        <TableContainer component={Paper} style={{ maxHeight: "400px", overflow: "auto"}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="MuiTableCell-id">#</TableCell>
                <TableCell className="MuiTableCell-filename">
                  Filename
                </TableCell>
                <TableCell className="MuiTableCell-no_of_qn">
                  Questions Count
                </TableCell>
                <TableCell className="MuiTableCell-createdDate">
                  Created Date
                </TableCell>
                <TableCell className="MuiTableCell-createdDate">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchHistory.map((row) => (
                <TableRow key={row.row_number}>
                  <TableCell className="table-cell">{row.row_number}</TableCell>
                  <TableCell onClick={() => handleFilenameClick(row.ai_app_file_details_id)} style={{ cursor: "pointer", color: "blue" }} className="table-cell">{row.user_pdf_file_name}</TableCell>
                  <TableCell className="table-cell">{row.no_of_qn}</TableCell>
                  <TableCell className="table-cell">{row.created_date}</TableCell>
                  <TableCell className="table-cell" style={{ display: "flex", justifyContent: "space-between" }}>
                    {row?.is_saved === true ? (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={(event) => {
                          handleClick(event, row.row_number, row.ai_app_file_details_id);
                          handleDeleteTopic();
                        }}
                        style={{ width: "45%" }}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={(event) => {
                          handleClick(event, row.row_number, row.ai_app_file_details_id);
                          handleSaveTopic();
                        }}
                        style={{ width: "45%" }}
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        handleClick(event, row.row_number, row.ai_app_file_details_id);
                        handlePrint();
                      }}
                      style={{ width: "50%" }}
                    >
                      Print
                    </Button>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* Discard dialog with loading spinner */}
      <Dialog open={openDiscardDialog} onClose={handleDiscardCancel}>
        <DialogTitle>Discard Existing Chat?</DialogTitle>
        <DialogContent>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            "Are you sure you want to discard the existing chat and load this PDF?"
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardCancel} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleDiscardConfirm} color="primary" disabled={loading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for save success */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        //message="PDF saved successfully"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        //sx={{ backgroundColor: "blue", color: "white" }}
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{ backgroundColor: "#4caf50", color: "#ffffff" }} // Change background and text color
        />
      </Snackbar>
    </Layout>
  );
};

export default PdfHistory;
