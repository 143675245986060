import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
    return (
        <Box 
            component="footer" 
            sx={{ py: 2, px: 4, backgroundColor: '#3f51b5', color: 'white', textAlign: 'center' }}
        >
            <Typography variant="body2">
                &copy; {new Date().getFullYear()} Informatics India Ltd. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
