import React from 'react';
import './App.css';
// import Register from "./components/Register";
import Home from "./components/Home";
import Login from "./components/Login";
import PdfHistory from './components/PdfHistory';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IdleTimeout from './components/SessionManagement';
import {HighlightTermProvider} from './utils/HighlightContext'

function App() {
    return (
        <HighlightTermProvider>
        <Router>
            <IdleTimeout />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/login' element={<Login />} />
                <Route path="/pdfhistory" element={<PdfHistory />} />
                {/* <Route path='/register' element={<Register />} /> */}
                <Route path='/home' element={<Home />} />
            </Routes>
        </Router>
        </HighlightTermProvider>
    );
}

export default App;
