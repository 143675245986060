import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import "./static/suggestions.css";
import api from "./api/endpoints";

// Define types for props and suggestions
interface Suggestion {
  [key: string]: string;
}

interface AccordionExpandIconProps {
  onSuggestionSelect: (key: string) => void;
  suggestions: any;
}

// Custom styled div with border radius and overflow hidden
const WrapperDiv = styled("div")(({ theme }) => ({
  borderRadius: "20px",
  overflow: "hidden",
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

// Styled Accordion with border-radius
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: "20px",
  boxShadow: "none",
  backgroundColor: "transparent",
  margin: "0px",
  border: "none",
  "&:before": {
    display: "none",
  },
}));

// Styled AccordionSummary with adjusted padding and reduced height
const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "25px",
  backgroundColor: "transparent",
  "&.MuiAccordionSummary-root": {
    padding: "0 16px",
  },
  "& .MuiAccordionSummary-content": {
    margin: "4px 0",
  },
}));

// Styled AccordionDetails with no background and custom padding
const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5, 1), // Reduced vertical padding
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column", // Ensure items stack vertically
  overflowY: "auto", // Allow vertical scrolling
  maxHeight: "150px", // Set a maximum height for the suggestions area
}));

const AccordionExpandIcon: React.FC<AccordionExpandIconProps> = ({
  onSuggestionSelect,
  suggestions,
}) => {
  const [suggestions1, setSuggestions1] = useState<Suggestion[]>(suggestions);
  const [userId, setUserId] = useState<string | null>(null);
  const [expanded, setExpanded] = useState(false); // Track accordion state
  const suggestionsEndRef = useRef<HTMLDivElement | null>(null); // Ref for auto-scroll

  // Simulate fetching userId from session storage or session context
  useEffect(() => {
    const sessionUserId = localStorage.getItem("user_id"); // Placeholder for session storage value
    if (sessionUserId) {
      setUserId(sessionUserId);
    }
  }, []);

  // Fetch suggestions when userId is set
  // useEffect(() => {
  //   if (userId) {
  //     const formData = new FormData();
  //     formData.append("user_id", userId.toString());

  //     fetch(`${api.baseApi}/api/get_auto_suggestions/`, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         //setSuggestions(data.list_of_suggestions || []);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching suggestions:", error);
  //       });
  //   }
  // }, [userId]);

  // Scroll to the bottom of the suggestions
  useEffect(() => {
    if (suggestionsEndRef.current) {
      suggestionsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [suggestions]);

  // Function to handle click event and get the key of the suggestion
  const handleSuggestionClick = (key: string) => {
    onSuggestionSelect(key);
    setExpanded(false); // Collapse accordion when a suggestion is selected
  };

  return (
    <WrapperDiv>
      <StyledAccordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <StyledAccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Suggestions
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {/* Suggestions rendered as plain text */}
          {suggestions.length > 0 ? (
            <>
              {suggestions.map((suggestions1: any, index: any) => {
                // const [key, value] = Object.entries(suggestions1)[0];

                return Object.entries(suggestions1).map(([key, value], idx) => (
                  // return (
                  <Typography
                    key={index}
                    onClick={() => handleSuggestionClick(key)}
                    className="suggestion-text"
                    sx={{ textAlign: "left" }} // Align text to the left
                  >
                   • {String(value)}
                  </Typography>
                ));
              })}
              <div ref={suggestionsEndRef} /> {/* Reference for scrolling */}
            </>
          ) : (
            <Typography>No suggestions available.</Typography>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </WrapperDiv>
  );
};

export default AccordionExpandIcon;
