import React, { useState, useEffect, useRef } from "react";
import "./static/chat.css"; // Ensure you have this CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import Suggestions from "./Suggestions";
import ReactMarkdown from "react-markdown";
import { SingleKeyword } from "@react-pdf-viewer/search"; // Import keyword type
import api from "./api/endpoints";
import {useHighlightTerm} from "../utils/HighlightContext"
import { notify } from "../utils/constants";

// Define types for messages
interface Message {
  text: string;
  sender: "user" | "bot";
  isMarkdown?: boolean;
}

const API_URL = `${api.baseApi}/api/pdf_query/`; // Replace with your FastAPI endpoint URL

interface ChatBotProps {
  pdfUploaded: boolean; // Add a prop to indicate if a PDF is uploaded
  setPdfUploaded: any;
  isInitialpdfUpload: boolean;
  showSuggestions: boolean;
}

const ChatBot: React.FC<ChatBotProps> = ({
  pdfUploaded,
  setPdfUploaded,
  isInitialpdfUpload,
  showSuggestions,
}) => {
  // Load messages from localStorage if available
  const storedMessages: Message[] = JSON.parse(
    localStorage.getItem("chatMessages") || "[]"
  );
  const [messages, setMessages] = useState<Message[]>(storedMessages);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isChatDisabled, setIsChatDisabled] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [suggestions, setSuggestions] = useState<string[]>(() => {
    const data = localStorage.getItem("suggestionsData");
    return data ? JSON.parse(data) : [];
  }); // Store suggestions
  // const [showSuggestions, setShowSuggestions] = useState<boolean>(() => {
  //   const storedValue = localStorage.getItem("isSavedSuggestions");
  //   return storedValue === "true" ? true : false; // Default to false if not found
  // }); // Control suggestions visibility

  //const [keywords, setKeywords] = useState<SingleKeyword[] | null>(null);
  //const [usedPdfIds, setUsedPdfIds] = useState<Set<string>>(new Set());
  //const [pdf_check, setLoading] = useState<boolean>(false);
  // Save messages to localStorage whenever they change

  const {addTerm,searchHighlightTerm,removeAllTerm}=useHighlightTerm()

  useEffect(()=>{
    return ()=>{
      if(searchHighlightTerm.searchTerm!==''){
        removeAllTerm();

      }
    }
  },[])

  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages));
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  // Automatically trigger the "Summary of the Document" question when PDF is uploaded
  useEffect(() => {
    if (pdfUploaded) {
      setMessages([]);
      localStorage.removeItem("chatMessages");
      console.log("First question");
      handleSend("Summary of the Document");
      //pdfUploaded(false);
      setPdfUploaded(false);
      fetchSuggestions();
    }
    //fetchSuggestions();
  }, [pdfUploaded]);

  useEffect(() => {
    if (showSuggestions) {
      fetchSuggestions();
    }
    //fetchSuggestions();
  }, [showSuggestions]);
  // Function to fetch suggestions
  const fetchSuggestions = async () => {
    // setSuggestions([]);
    try {
      const userId = localStorage.getItem("user_id");
      const formData = new FormData();
      if (userId) formData.append("user_id", userId);

      const response = await fetch(`${api.baseApi}/api/get_auto_suggestions/`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      console.log(data.list_of_suggestions);
      setSuggestions(data.list_of_suggestions);
      localStorage.setItem("isSavedSuggestions", "true");
      //setShowSuggestions(true); // Show suggestions after they are fetched
      localStorage.setItem(
        "suggestionsData",
        JSON.stringify(data.list_of_suggestions)
      );
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSend = async (messageToSend?: string) => {
    const message = messageToSend || inputValue; // Use the provided message or the current input value
    setInputValue(""); // Clear input after sending
    console.log("re-excute success");
    if (message.trim()) {
      const newUserMessage: Message = { text: message, sender: "user" };
      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      setLoading(true);
      setIsChatDisabled(true); // Disable chat when sending a message
      const userId = localStorage.getItem("user_id");
      const pdf_file_id = localStorage.getItem("pdf_file_id");
      const informaticscustomerId = localStorage.getItem("informaticscustomer_id");
      // Create a FormData object and append the question
      const formData = new FormData();
      if (userId) formData.append("user_id", userId);
      if (pdf_file_id) formData.append("pdf_file_id", pdf_file_id);
      if (informaticscustomerId) formData.append("informaticscustomer_id", informaticscustomerId);
      formData.append("question", message);

      // Call the FastAPI endpoint with the user's question using FormData
      try {
        const response = await fetch(API_URL, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        console.log(data.msg)
        if(data?.msg){
          notify("info",data?.msg)
        }
        // Add the bot's response to the chat messages
        const newBotMessage: Message = {
          text: data.answer || "Error: No response",
          sender: "bot",
          isMarkdown: true,
        };
        setMessages((prevMessages) => [...prevMessages, newBotMessage]);
      } catch (error) {
        const errorMessage: Message = {
          text: "Error: Could not reach the server.",
          sender: "bot",
          isMarkdown: true,
        };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }

      setLoading(false);
      setIsChatDisabled(false); // Re-enable chat after response is received
    }
  };
  const handleLinkClick = (href: any) => {
    // Handle internal links (like "/abstract") here
    //console.log("Link clicked:", decodeURIComponent(href).replaceAll(";"," "));
    if(href!==''){
      addTerm({searchTerm:decodeURIComponent(href)})
      setTimeout(()=>{

        removeAllTerm();
      },2000)
    }
  };
  //console.log("This is highlight search term>>>>>> ",searchHighlightTerm)

  const handleSuggestionSelect = (suggestion: string) => {
    setInputValue(suggestion); // Set the input value to the selected suggestion
    handleSend(suggestion); // Send the selected suggestion immediately
    //setShowSuggestions(false);
  };

  return (
    <div className={`chat-window ${isInitialpdfUpload ? "" : "disabled"}`}>
      {/* Add conditional disabling class */}
      <div className="messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${
              message.sender === "user" ? "message-user" : "message-bot"
            }`}
          >
            {message.sender === "user" && (
              <div className="message-wrapper user">
                <div className="message-content user-content">
                  <span>{message.text}</span>
                </div>
              </div>
            )}
            {message.sender === "bot" && (
              <div className="message-wrapper bot">
                <div className="message-content bot-content">
                  {message.isMarkdown ? (
                    <ReactMarkdown
                      components={{
                        a: ({ href, children }) => (
                          <a
                            href={href}
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default link behavior
                              handleLinkClick(href); // Call custom handler
                            }}
                          >
                            {children}
                          </a>
                        ),
                      }}
                    >
                      {/* {`${message.text} [click me](Encapsulation;is;an;Object;Oriented) [click me 2](In;java;programming,;multiple;and;hybrid;inheritance;is;supported;through)`} */}
                      {message.text}
                    </ReactMarkdown>
                  ) : (
                    <span>{message.text}</span>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}

        {/* Show loading dots while waiting for the bot response */}
        {loading && (
          <div className="loader bot">
            <div className="loader-content bot-content">
              <span className="loading-dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </div>
          </div>
        )}

        {/* Reference for scrolling */}
        <div ref={messagesEndRef} />
      </div>
      {/* Only show suggestions if a PDF is uploaded */}
      {showSuggestions && (
        <div className="suggestions">
          <Suggestions
            onSuggestionSelect={handleSuggestionSelect}
            suggestions={suggestions}
          />
        </div>
      )}
      {/* Disable input area if no PDF is uploaded */}
      <div className="input-area">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
          placeholder={
            isInitialpdfUpload
              ? "Type your message..."
              : "Upload a PDF to start chatting"
          }
          disabled={!isInitialpdfUpload || isChatDisabled} // Disable input if no PDF is uploaded
        />
        <button
          className="send-button"
          onClick={() => handleSend()}
          disabled={!isInitialpdfUpload || isChatDisabled} // Disable send button if no PDF is uploaded
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
};

export default ChatBot;
