import * as React from "react";
import { useState, useEffect, ChangeEvent, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "./static/PdfUpload.css";
import JumpToFirstMatchExample from "./JumpToFirstMatchExample";
import { SingleKeyword } from "@react-pdf-viewer/search";
import api from "./api/endpoints";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { notify } from "../utils/constants";
interface PdfUploadProps {
  onUpload: (uploaded: boolean) => void; // Callback to notify parent about upload status
  setIsInitialpdfUpload: any;
  setShowSuggestions: any;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const PdfUpload: React.FC<PdfUploadProps> = ({
  onUpload,
  setIsInitialpdfUpload,
  setShowSuggestions
}) => {
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [fileName, setFileName] = useState('');
  const [oldFileName, setOldFileName] = useState('');
  const [pdfError, setPdfError] = useState<string>("");
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<SingleKeyword[] | null>(null);
  const [isValidating, setIsValidating] = useState(false);
  // State for the dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [containerHeight, setContainerHeight] = useState<number>(450);

  const allowedFiles: string[] = ["application/pdf"];
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // Set initial height based on window size
    const handleResize = () => {
      const height = window.innerHeight < 500 ? 450 : 500;
      setContainerHeight(height);
    };

    handleResize(); // Set the initial height
    window.addEventListener("resize", handleResize); // Listen for resize events

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Check for PDF path in localStorage on component mount
    const storedPdfPath = localStorage.getItem("pdf_file_path");
    if (storedPdfPath) {
      setPdfFile(storedPdfPath); // Set the PDF file from local storage
    }
  }, []);

  const handleFile = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = e.target.files?.[0]; // Get the first selected file
    if (!selectedFile) return; 
    // Check if a PDF is already uploaded
    const fileSizeLimit = 10 * 1024 * 1024; // 5 MB in bytes

    // Check file size limit
    if (selectedFile.size > fileSizeLimit) {
        setPdfError('File size should be less than 10 MB');
        setFileName(''); // Clear filename on error
        return;
    } else {
        setOldFileName(fileName)
        setPdfError(''); // Clear any previous errors
        setFileName(selectedFile.name); // Show file name if valid
    }
    if (pdfFile) {
      setNewFile(selectedFile ?? null); // Set new file or null if undefined
      setDialogOpen(true); // Open dialog
    } else {
      if (selectedFile) { // Check if selectedFile is defined
        uploadPdf(selectedFile);
        
      }
    }
  };
  
  const uploadPdf = (file: File | null): void => {
    if (!file) return;

    if (allowedFiles.includes(file.type)) {
      setPdfError("");
      setUploading(true);
      setUploadProgress(0);
      uploadFile(file);
      //setIsInitialpdfUpload(true);
      localStorage.setItem("isPdfUploaded", "true");
    } else {
      setPdfError("Not a valid PDF: Please select only PDF files");
      setPdfFile(null);
      onUpload(false);
      setIsInitialpdfUpload(false);
    }
  };

  const handleDialogClose = (confirm: boolean): void => {
    setDialogOpen(false);
    if (confirm && newFile) {
      uploadPdf(newFile); // Upload the new PDF
    }else{
      setFileName(oldFileName);
      
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Clear the file input value
      }
    }
  };

  const simulateProgress = (): void => {
    let progress = 1;
    const interval = setInterval(() => {
      if (progress < 80) {
        progress++;
        setUploadProgress(progress);
      } else {
        clearInterval(interval);
      }
    }, 50);
  };
  const validateTokenLimit = async (): Promise<boolean> => {
    const informaticscustomerId = localStorage.getItem("informaticscustomer_id");
    if (!informaticscustomerId) {
      return false; // Return false if customer ID is missing
    }
  
    try {
      const queryParams = new URLSearchParams();
      queryParams.append("customer_id", informaticscustomerId);
  
      const response = await fetch(
        `${api.baseApi}/api/validate_tokens_limit/?${queryParams.toString()}`,
        {
          method: "GET",
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        console.log("File details:", data);
        if (data.msg == 'Success') {
          localStorage.setItem("isTokenValid", 'true');
          return true; // Return true if `msg` exists in the response
        } else {
          localStorage.setItem("isTokenValid", 'false');
          notify("info",data?.msg)
          return false; // Return false if `msg` is not present
        } // Assume true if file details fetched successfully
      } else {
        console.error("Failed to fetch file details:", response.status, response.statusText);
        return false; // Return false for failed response
      }
    } catch (error) {
      console.error("Error fetching file details:", error);
      return false; // Return false for exceptions
    }
  };
  

  const uploadFile = (file: File): void => {
    setPdfError("");
    const userId = localStorage.getItem("user_id");
    const sessionId = localStorage.getItem("session_key");

    const formData = new FormData();
    if (userId) formData.append("user_id", userId);
    if (sessionId) formData.append("session_id", sessionId);
    formData.append("file", file);
    simulateProgress();
    fetch(`${api.baseApi}/api/ai_upload_pdf/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch content.");
        }
        return response.json();
      })
      .then((data) => {
        const fileURL = data.file_path;
        localStorage.setItem("pdf_file_path", fileURL);
        localStorage.setItem("pdf_file_id", data.pdf_file_id);
        setPdfFile(fileURL);
        setUploading(false);
        setUploadProgress(100);
        fetchContent();
        onUpload(true);
        setIsInitialpdfUpload(true);
        setShowSuggestions(true);
      })
      .catch((error) => {
        setPdfError("File upload failed. Please try again.");
        setUploading(false);
        setUploadProgress(0);
        console.error("Error uploading file:", error);
        onUpload(false);
        setIsInitialpdfUpload(false);
        setShowSuggestions(false);
      });
  };

  const fetchContent = (): void => {
    fetch(`${api.baseApi}/get-highlight-content/`)
      .then((response) => response.json())
      .then((data) => {
        const fetchedKeywords: SingleKeyword[] = data.content.map(
          (keyword: string) => ({ keyword })
        );
        setKeywords(fetchedKeywords);
      })
      .catch((error) => {
        console.error("Error fetching highlight content:", error);
      });
  };
  const truncateFileName = (name:string, length = 25) => 
    name.length > length ? `${name.substring(0, length)}...` : name;

  return (
    <div className="pdf-upload-container">
      <form>
        {/* <input
          type="file"
          className="form-control"
          onChange={handleFile}
          accept=".pdf"
        /> */}
        <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        onClick={async (e) => {
          if (isValidating) return;
          setIsValidating(true);
          const isTokenValid = await validateTokenLimit();
          setIsValidating(false);
          if (!isTokenValid) {
            setPdfError("Token validation failed. Please try again.");
            e.preventDefault(); // Prevent file selection dialog if validation fails
            return;
          }
          setPdfError(""); // Clear previous errors if validation succeeds
        }}
      >
        Upload PDF file
        <VisuallyHiddenInput
          type="file"
          onClick={(e) => {
            const isTokenValid = localStorage.getItem("isTokenValid");
            if (isTokenValid === "false") {
              e.preventDefault(); // Prevent the file dialog from opening if validation failed
            }
          }}
          onChange={handleFile}
          accept=".pdf"
        />
      </Button>
        {/* Display file name */}
      {fileName &&  <span className="text" title={fileName}>{truncateFileName(fileName)}</span>}
      {/* Display error message if file size exceeds limit */}
      {pdfError && <span className="text-danger">{pdfError}</span>}
      </form>

      {uploading && (
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${uploadProgress}%` }}
          >
            {uploadProgress}%
          </div>
        </div>
      )}

      <div className="viewer" style={{ height: `${containerHeight}px` }}>
        {pdfFile ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <JumpToFirstMatchExample
              fileUrl={pdfFile}
              keyword={keywords || []}
            />
          </Worker>
        ) : (
          <p>No file is selected yet</p>
        )}
      </div>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You already have a PDF uploaded. Do you want to discard it and upload a new one?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Discard and Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PdfUpload;
