import React, { useEffect, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'; // Import the icon
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Icon for Logout
import api from './api/endpoints';

// Define the available settings with their corresponding icons
const settings = [
  // { name: 'Personal Library', icon: <FolderOpenIcon /> },
  { name: 'Logout', icon: <ExitToAppIcon /> }
];

const ResponsiveAppBar: React.FC = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate(); // useNavigate hook for navigation
  const [username, setUsername] = useState<string>('');

  // Get the username from local storage on component mount
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  // Function to handle user menu open event
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  // Function to handle user menu close event
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Function to handle logout action
  const handleLogout = () => {
    // Clear session storage/local storage or any global state
    sessionStorage.clear(); // Clear session storage
    localStorage.clear();   // Clear local storage if using it

    // Redirect to JgateNext.com
    window.location.href = `${api.frent_end_url}`;
  };

  // Function to handle the click event of the search history button
  const handleSearchHistoryClick = () => {
    navigate('/pdfhistory');
  };

  const handleChatbotClick = () => {
    navigate('/');
  };
  // Function to handle clicking "Personal Library" and opening a new tab
  const handlePersonalLibraryClick = () => {
    window.open(`${api.frent_end_url}/my-library/searches/`, '_blank');
    handleCloseUserMenu(); // Close the menu after clicking
  };
  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#ac00e6', height: '57px', fontFamily: 'Helvetica Neue' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img
              src="https://diskoverapi.jgatenext.com/static/media/Jgate_logo.png"
              alt="Logo"
              style={{
                height: '40px',
                width: '40px',
                borderRadius: '50%',
                objectFit: 'cover',
                marginRight: '8px',
              }}
            />
            <Typography variant="h6" noWrap sx={{ color: '#FFFFFF', fontFamily: 'Helvetica Neue' }}>
              JGateNext AI
            </Typography>
          </Box>
          <IconButton sx={{ marginRight: 2 }} onClick={handleChatbotClick} aria-label="Chatbot">
            <AutoAwesomeIcon sx={{ color: 'orange' }} />
          </IconButton>
          <IconButton sx={{ marginRight: 2 }} onClick={handleSearchHistoryClick} aria-label="Search History">
            <AccessTimeOutlinedIcon sx={{ color: '#FFFFFF' }} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 0, textAlign: 'right', mr: 2, fontFamily: 'Helvetica Neue' }}>
            {username ? `Hi, ${username}` : 'Hi'}
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(({ name, icon }) => (
                <MenuItem
                key={name}
                onClick={
                  name === 'Logout'
                    ? handleLogout
                    : name === 'Personal Library'
                    ? handlePersonalLibraryClick
                    : handleCloseUserMenu
                }
              >
                {icon}
                <Typography sx={{ textAlign: 'center', marginLeft: '8px' }}>{name}</Typography>
              </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
