import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import PdfUpload from "./PdfViewer";
import ChatBot from "./chatbot";
import Footer from "./Footer"; // Import the Footer component
import "./static/home.css"; // Make sure to create this CSS file for styling
import api from "./api/endpoints";
import Layout from "./Layout";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [pdfUploaded, setPdfUploaded] = useState<boolean>(false); // Track PDF upload status
  const [isInitialpdfUpload, setIsInitialpdfUpload] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("isPdfUploaded");
    return storedValue === "true" ? true : false; // Default to false if not found
  });

  const [showSuggestions, setShowSuggestions] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("isSavedSuggestions");
    return storedValue === "true" ? true : false; // Default to false if not found
  });
  const is_reexcute = localStorage.getItem('is_reexcute')
  console.log('is_reexcute', is_reexcute);
  useEffect(() => {
    if (is_reexcute) {
      //pdfUploaded(false);
      console.log("calling API")
      setIsInitialpdfUpload(true);
      setShowSuggestions(true);
      localStorage.setItem("is_reexcute", 'false');
    }
    //fetchSuggestions();
  }, [is_reexcute]);
  useEffect(() => {
    // Extract token from URL
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const userId = localStorage.getItem("user_id");

    if (token) {
      // Store the token in local storage or session storage
      localStorage.setItem("sso_token", token);
      // Validate the token by calling the API
      validateToken(token);
    } else if (userId) {
      navigate("/");
    } else {
      sessionStorage.clear(); // Clear session storage
      localStorage.clear();
      window.location.href = `${api.frent_end_url}`;
    }
  }, []);
  const handleClick = () => {
    // Simulate a delay of 2 seconds (2000 milliseconds)
    setTimeout(() => {}, 2000); // 2000 milliseconds = 2 seconds
  };
  const validateToken = async (token: string) => {
    try {
      const formData = new FormData();
      formData.append("sso_token", token);
      const response = await fetch(
        `${api.baseApi}/api/validate_sso_token_ai/`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (response.ok && data.data === "Success") {
        // Token is valid, allow access
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem(
          "informaticscustomer_id",
          data.informaticscustomer_id
        );
        localStorage.setItem("session_key", data.session_key);
        localStorage.setItem("username", data.user_name);
        localStorage.removeItem("sso_token");
        handleClick();
        navigate("/");
        window.location.reload();
      } else {
        // Token is invalid, redirect to login page
        //navigate("/login");
        window.location.href = `${api.frent_end_url}`;
      }
    } catch (error) {
      console.error("Error validating token:", error);
      //navigate("/login");
      window.location.href = `${api.frent_end_url}`;
    }
  };

  // Callback function to handle PDF upload state change
  const handlePdfUpload = (uploaded: boolean) => {
    setPdfUploaded(uploaded);
  };

  return (
    <Layout>
      <div
        className="App"
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        {/* Content Container */}
        <div
          className="container"
          style={{ marginTop: "40px", flex: 1, overflow: "auto" }}
        >
          <div className="left-side">
            <PdfUpload
              onUpload={handlePdfUpload}
              setIsInitialpdfUpload={setIsInitialpdfUpload}
              setShowSuggestions={setShowSuggestions}
            />{" "}
            {/* Pass the callback to PdfUpload */}
          </div>
          <div className="right-side">
            <ChatBot
              pdfUploaded={pdfUploaded}
              setPdfUploaded={setPdfUploaded}
              isInitialpdfUpload={isInitialpdfUpload}
              showSuggestions={showSuggestions}
            />{" "}
            {/* Pass the PDF upload status to ChatBot */}
          </div>
        </div>
        <Footer /> {/* Footer at the bottom */}
      </div>
    </Layout>
  );
};

export default Home;
