import React from 'react';
import { Box } from '@mui/material';
import ResponsiveAppBar from './ResponsiveAppBar';  // Header Component
import Footer from './Footer';  // Footer Component
import { ToastContainer } from "react-toastify";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header */}
      <ResponsiveAppBar />

      {/* Main Content */}
      <ToastContainer />
        {children}
  

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Layout;
